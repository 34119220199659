<template>
  <div>


        <van-nav-bar
            title="我的上传"
        />
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">        
            <van-list                 
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了~"
                @load="onLoad" 
                class="user-poster"
                id='user-poster'>

                <van-cell class="user-root"  v-for="(p,index) in list" :key="index">
                <!-- 用户头像和id -->
                    <div class="header">
                        <div @click.stop class="user-head">
                            <router-link  :to="'/personDetail/' + p.userId">
                                <img :src="p.avatar" alt="">
                            </router-link>
                        </div>

                        <div @click="goDetail(index)" class="user-name">
                            <div class="user-id">{{p.nickname}}</div>
                            <div class="user-time">{{p.created}}</div>
                        </div> 
                        <van-popover
                            v-model="showPopover[index]"
                            placement="bottom-end"
                            trigger="click"
                            :actions="actions"
                            @select="onSelect"
                        >
                            
                            <template #reference>
                                <van-icon   name="ellipsis" size="20" color="#b2b2b2"/>
                            </template>
                        </van-popover>  


                    </div>

                    <div @click="goDetail(index)">
                        
                    <div class="user-content1">
                        <span @click.stop="goSearch(p.difficulty)" style="color: dodgerblue;">#{{p.difficulty}} </span>
                        <span @click.stop="goSearch(p.location)" style="color: dodgerblue;margin-left: 8px;"> #{{p.location}}</span>
                            
                    </div >
                        
                    <div class="user-content1" style="margin-top:0; padding:5px;margin-bottom:10px; font-size: 16px;">
                        {{p.content}}
                    </div>

                        <div class="user-photo">
                            <img :src="p.pic" alt="" @click.stop="getImage(index)">
                        </div>

                        <div class="icons" >

                            <div style="display:flex">

                                <van-icon @click.stop="CollectRoute(p,index)" v-show="!temp[index]" name="star-o" size="21"/>
                                <van-icon @click.stop="unCollectRoute(p,index)" v-show="temp[index]" color="#FFD700" name="star" size="21"/>
                                <span v-show="!p.collectCounts">收藏</span>
                                <span style="font-size: 15px;" v-show="p.collectCounts">
                                    {{p.collectCounts}}
                                </span>
                            </div>   


                            <div style="display:flex">
                                <van-icon style="margin-top:2px" name="comment-o" size="21"/>
                                <span v-show="!p.commentCounts">
                                    评论
                                </span>
                                <span style="font-size: 15px;" v-show="p.commentCounts">
                                    {{p.commentCounts}}
                                </span>
                                
                            </div>

                            <div style="display:flex">
                                <van-icon @click.stop="LikeRoute(p,index)" v-show="!temp1[index]" name="good-job-o" size="22"/>
                                <van-icon @click.stop="unLikeRoute(p,index)" v-show="temp1[index]" color="#e93855" name="good-job"  size="22"/>
                                <span v-show="!p.likesCounts">点赞</span>
                                <span style="font-size: 15px;" v-show="p.likesCounts">
                                    {{p.likesCounts}}
                                </span>
                                
                            </div>   
                        </div>
                    </div>

                </van-cell>  
            </van-list>
        </van-pull-refresh>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import { Dialog } from 'vant';
import {Toast} from 'vant'
export default {
    data() {
        return {
            showPopover: [],
            actions: [{ text: '删除路线', icon:'delete-o'}],
            temp:[],
            temp1:[],
            likes:[],
            loading: false,
            finished: false,
            refreshing: false,
            collections:[],
            collectionId:[],
            userId:'',
            posterInfo:[],
            list:[],
            k:0,
            t:5
        }
    },

    methods:{
        getImage(index){
            ImagePreview([
                this.posterInfo[index].pic
            ]);
        },
        
        Delete(){
            console.log("aaa")
        },

        stopPropagation(e) {
            e = e || window.event;
            if (e.stopPropagation) { //W3C阻止冒泡方法
                e.stopPropagation();
            } else {
                e.cancelBubble = true; //IE阻止冒泡方法
            }
        },

        goDetail(index){

            this.$router.push({
                name:"detail",
                params:{
                    posterId: this.posterInfo[index].id
                }
            })
        },
        getUploading(id){
            this.$axios.get("/blogs/"+id).then(
                res=>{
                    this.posterInfo = res.data.data
                    this.posterInfo.reverse()
                    this.onLoad()
                }
            )
        },
        onLoad() {
            setTimeout(()=>{
                if(this.refreshing){
                    this.list = []
                    this.k = 0
                    this.t = 5
                    this.refreshing = false
                }
                if(this.posterInfo.length<this.t){
                    this.list = this.posterInfo
                }else{
                    for (let i = this.k; i < this.k+5; i++) {
                        this.list.push(this.posterInfo[i]);
                    }

                    this.k = this.k+5
                    this.t = this.t+5
                }

                this.loading = false;
                if (this.list.length >= this.posterInfo.length) {
                    this.finished = true;
                }

                 //收藏

                this.temp = []
                this.list.forEach(a => {
                if(this.collections.length == 0){
                    this.temp.push(false)
                }

                for (let k = 0; k < this.collections.length; k++) {
                    const b = this.collections[k];              
                    if (a.id == b.articleId) {
                        this.temp.push(true)
                        break;
                    }
                    else if(k==this.collections.length-1){
                        this.temp.push(false)
                    }
                }
                });

                //点赞

                this.temp1 = []
                this.list.forEach(a => {
                if(this.likes.length == 0){
                    this.temp1.push(false)
                }
                for (let k = 0; k < this.likes.length; k++) {
                    const b = this.likes[k];               
                    if (a.id == b.articleId) {
                        this.temp1.push(true)
                        break;
                    }
                    else if(k==this.likes.length-1){
                        this.temp1.push(false)
                    }
                }
                    
                });

            },1000)


        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },

        onSelect(action) {
            var index =0 
            for (let i = 0; i < this.showPopover.length; i++) {
                if(this.showPopover[i] == true){
                    index = i
                }       
            }

            var id = this.list[index].id

            if(action.text == "删除路线"){
                Dialog.confirm({
                    message: '确定删除这条路线吗？' 
                }).then(()=>{
                    this.$axios.post("/blogDel/"+ id,{},{    
                        headers: {"Authorization":localStorage.getItem("token")}
                    }).then(
                        res =>{
                            Toast("删除成功!")
                            this.getUploading(this.userId)
                        }
                    )

                    console.log(index)
                }).catch(()=>{
                    
                })
            }
        },

        collectCount(p){
            p.collectCounts = p.collectCounts + 1
            this.$axios.post("/blog/editComments",{
                id: p.id,
                commentCounts : p.commentCounts,
                collectCounts: p.collectCounts,
                likeCounts: p.likesCounts
            })
        },

        CollectRoute(p,index){
            this.isCollect = true;
            this.$axios.post("/collection/addCollect",{
                articleId: p.id,
                userId : this.$store.getters.getUser.id
            },{    
                headers: {"Authorization":localStorage.getItem("token")}
            }).then(
                res=>{
                    Toast("收藏成功！")
                    this.collectCount(p)
                    this.temp[index] = true;
                }
            )
        },

        unCollectCount(p){
            this.isCollect = false;
            p.collectCounts = p.collectCounts - 1
            this.$axios.post("/blog/editComments",{
                id: p.id,
                commentCounts : p.commentCounts,
                collectCounts: p.collectCounts,
                likesCounts:p.likesCounts
            })
        },


        unCollectRoute(p,index){
            this.$axios.post("/collection/cancelCollect",{
                articleId: p.id,
                userId : this.$store.getters.getUser.id
            }).then(
                res =>{
                    this.unCollectCount(p)
                    Toast("已取消")
                    this.temp[index] = false;
                }
            )
        },

        getCollects(user_id){
            this.$axios.get("/collection/collects/"+user_id).then(
                res =>{
                    this.collections = res.data.data
                }
            )
        },


        likeCount(p){
            p.likesCounts = p.likesCounts +1;
            this.$axios.post("/blog/editComments",{
                id: p.id,
                commentCounts : p.commentCounts,
                collectCounts: p.collectCounts,
                likesCounts: p.likesCounts,
            }).then(
                res=>{
                    console.log("点赞完后的posterinfo",this.posterInfo)
                }
            )
        },

        LikeRoute(p,index){
            this.isLike = !this.isLike
            this.$axios.post("/like/addLike",{
                articleId: p.id,
                userId : this.$store.getters.getUser.id
            },{    
                headers: {"Authorization":localStorage.getItem("token")}
            }).then(
                res =>{
                    this.likeCount(p)
                    this.temp1[index] = !this.temp1[index];
                    Toast("已点赞")
                }
            )

        },

        unLikeRoute(p,index){
            this.isLike = !this.isLike
            this.$axios.post("/like/cancelLike",{
                articleId: p.id,
                userId : this.$store.getters.getUser.id
            }).then(
                res =>{
                    this.unLikeCount(p)
                    Toast("已取消")
                    this.temp1[index] = !this.temp1[index]
                }
            )
        },

        unLikeCount(p){
            p.likesCounts = p.likesCounts -1;
            this.$axios.post("/blog/editComments",{
                id: p.id,
                commentCounts : p.commentCounts,
                collectCounts: p.collectCounts,
                likesCounts:p.likesCounts
            })
        },

        isLiked(user_id){
            
            this.$axios.get("/like/likes/"+user_id).then(
                res =>{
                    this.likes = res.data.data
                }
            )
        },

    },


    mounted() {
        this.userId = this.$store.getters.getUser.id
        // this.collection(this.userId)
        this.getUploading(this.userId)  
        this.isLiked(this.userId) 
        this.getCollects(this.userId)
    },
}
</script>

<style>
  
.user-poster {

    width:  100%;
    height: auto;
    box-sizing: border-box;
    background-color: white;
    color: black;
}

.user-root {
    /* border-radius: 25px; */
    overflow: hidden;
    border-top: solid 0.5px #e6e6e6;
}

.header{
    
    display: flex;
    height: 45px;
}

.header i{
    position: absolute;
    right: 0;
}

.header span{
    position:absolute;
    right:0; 
    width: 40px;
    height: 30px;
    
}





.van-popover--light{
    color: rgb(255, 0, 0) !important;
}



.user-head {
    width: 43px;
    height: 43px;
    /* background-color: rgb(68, 138, 138); */
}

.user-head img {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}

.user-name {
    margin-left: 5px;
    color:black;
    font-weight: 700;
    font-size: 15px;
}
.user-time {
    color: #b7b7b7;
    font-size: 12px;
    margin: 2px;
}

.user-content1 {
    padding: 5px;
    color: black;
    margin-left: 45px;
    font-size: 14px;
    /* background-color: yellow; */
}

.user-photo{
    display: flex;
    margin-left: 45px;
    margin-bottom: 10px;
    width: 87%;
    /* background-color: wheat; */

}

.user-photo img{
    border:  0.5px solid rgb(168, 168, 168);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    
}

.search-login a{
    text-decoration:none;
    color:black;
}

.icons{
    position: relative;
    z-index: 10;
    display: flex;
    height: 30px;
    margin: 0 auto;
    width: 85%;
    display: flex;
    right: 0;
}

.icons div{
    width: 33%;

    margin: 5px 20px 5px 10px ;
}


.icons i{
    margin-left: 10px;
    position: absolute;
    z-index: 20;

}

.icons span{
    position: absolute;
    z-index: 40;
    margin-left: 40px;
    
}

</style>